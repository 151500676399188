<template>
    <div class="parts">
      <span class="float-left">
        <CButton color="primary" size="sm" @click="formInit">入力クリア</CButton>
        <slot name="right" class="float-right"></slot>
      </span>

      <span class="float-right ml-5" v-if="showSaveBtn">
        <CButton size="sm" color="info" @click="save('更新確認','更新しますか？')" v-if="id">更新</CButton>
        <CButton size="sm" color="danger" @click="save('登録確認','登録しますか？')" v-else >登録</CButton>
        <slot name="left"></slot>
      </span>

    </div>
</template>

<script>
    export default {
      props:{
        id: { default: null, type: Number },
        showCopy: {default: false, type: Boolean},
        showSaveBtn:{default: true, type: Boolean}
      },
      methods:{
        formInit:function(){
          this.$swal({
            title: "表示クリア",
            text: "表示クリアしますか？",
            // icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((isOk) => {
            if (isOk) {
              this.$emit("formInit");
            }
          });
        },copy:function(){
          this.$emit("copy");
        },save:function(title,text){
          this.$swal({
            title: title,
            text: text,
            buttons: true,
            dangerMode: true,
          })
          .then((isOk) => {
            if (isOk) {
              this.$emit("save");
            }
          });
        }
      }
    };
</script>
