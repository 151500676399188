<template>
  <div>
    <CCard class="p-0 m-0 form-card">
      <CCardHeader>
        <label class="header-title">販売店管理 {{isCollapsed ?"(一覧モード)":"(登録モード)"}}</label>
        <div class="card-header-actions">
          <CButton color="primary" size="sm" @click="isCollapsed=!isCollapsed">{{isCollapsed ? '登録':'一覧'}}モードへ</CButton>
        </div>
      </CCardHeader>

      <CCardBody class="p-0">
        <div>
          <CCollapse :show="!isCollapsed" :duration="200">
            <CCard class="notice_form p-0 m-0">
              <CCardBody>
                <CForm>
                  <CRow class="mt-0">
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="販売店名称" v-model="formData.name" addLabelClasses="required"/>
                    </CCol>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="販売店名称(カナ)" v-model="formData.nameKana" addLabelClasses="required"/>
                    </CCol>
                  </CRow>

                  <CRow class="mt-0">
                    <div class="col-6">
                      <div role="group" class="mt-0 form-group form-row">
                        <label for="prefectureId" class="col-form-label col-sm-3 required">都道府県</label>
                        <div class="col-sm-9">
                          <v-select
                              id="prefectureId"
                              v-model="formData.prefectureId"
                              :options="prefectures"
                              key="prefectureId"
                              label="prefectureName"
                              :reduce="prefectures => prefectures.prefectureId">
                            <template slot="no-options">データがありません。</template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="市区町村" v-model="formData.city" addLabelClasses="required"/>
                    </CCol>
                  </CRow>

                  <CRow class="mt-0">
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="連絡先担当者名" v-model="formData.contactName"/>
                    </CCol>
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="連絡先電話番号" @keypress="isNumber($event)"
                              v-model="formData.contactTel" addLabelClasses="required"/>
                    </CCol>
                  </CRow>

                  <CRow class="mt-0">
                    <CCol col="6">
                      <CInput class="mt-0" horizontal label="メモ" v-model="formData.contactMemo"/>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
              <CCardFooter>
                <SaveButtons :id="formData.id" @formInit="formInit" @save="save"/>
              </CCardFooter>
            </CCard>
          </CCollapse>
        </div>
      </CCardBody>
    </CCard>

    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword"></MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false">
          <!-- HEADER ソート可能にするために 必要-->
          <template slot-scope="{ column,index }" slot="idHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="nameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="addressHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="branchCountHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="updatedHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <!-- cell -->

          <template slot-scope="{ row,index }" slot="name"><div class="text-left">{{row.name}}</div></template>
          <template slot-scope="{ row,index }" slot="address"><div class="text-left">{{row.address}}</div></template>
          <template slot-scope="{ row,index }" slot="created"><div class="text-left">{{row.created}}</div></template>
          <template slot-scope="{ row,index }" slot="updated"><div class="text-left">{{row.updated}}</div></template>






          <template slot-scope="{ row,index }" slot="id">
            <div class="text-left">{{row.id}}</div>
          </template>
          <template slot-scope="{ row,index }" slot="after">
            <CButton color="success py-0 mr-1" @click="update(row)">編集</CButton>
          </template>

          <template slot-scope="{ row,index }" slot="after2">
            <CButton color="info py-0 " @click="toBranch(row)" >事業所</CButton>
          </template>
        </BeeGridTable>
      </CCardBody>
    </CCard>

    <CModal :show.sync="showBranch"
            size="xl"
            :centered="true"
            :title="agencyName+'の事業所管理'">

      <AgencyBranchManage :is-show="showBranch" :agency-id="agencyId" :is-modal="true" :agency-name="agencyName"/>

      <template #footer-wrapper><span></span></template>
    </CModal>
  </div>
</template>

<script>
import axios from 'axios'
import SaveButtons from "@/component/parts/SaveButtons";
import AgencyBranchManage from "@/component/parts/AgencyBranchManage";

import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
  mixins: [BeeMix],
  components: {
    AgencyBranchManage,SaveButtons,MyBeeHeader, MyBeeSearchBar
  },
  name: 'AgencyManage',
  data: () => {
    return {
      showBranch:false,
      agencyId:null,
      agencyName:null,
      searchBarPlaceHolder:"販売店,住所で検索",
      columns: [
        // {label: 'No', field: 'no', width: '80px', sortable: false},
        {title: '販売店ID', key: 'id',slot:'id',headSlot: 'idHead',width:80,align: 'center'},
        {title: '販売店', key: 'name',slot:'name',headSlot: 'nameHead',align: 'center'},
        {title: '住所', key: 'address',slot:'address',headSlot: 'addressHead',align: 'center',},
        {title: '販売店事業所数', key: 'branchCount',width:120,align:"center",headSlot: 'branchCountHead'},

        {title: '登録日時', key: 'created',slot:'created',headSlot: 'createdHead',align: 'center'},
        {title: '更新日時', key: 'updated',slot:'updated',headSlot: 'updatedHead',align: 'center'},
        {title: ' ', key: 'after',slot:"after",width:80,align: "center"},
        {title: ' ', key: 'after2',slot:"after2",width:100,align: "center"},
      ],
      selected: "",
      isCollapsed: true,
      orgTypes: [],
      prefectures: [],
      cities: [],
      formData: {
        id: null,
        name: null,
        nameKana: null,
        //namePosition: "P",
        //orgTypeCode: null,
        prefectureId: null,
        city: null,
        contactName: null,
        contactDept: null,
        contactTel: null,
        contactMemo: null
      },
    }
  },
  mounted() {
    this.getOptions();
  },
  methods: {
    checkedNamePosition: function (data) {
      console.log("checkedNamePosition:function(data){", data);
    },
    formInit: function () {
      this.formData = {namePosition: "P"};
    }, save: function () {
      axios.post('/api/agency/agencySave', this.formData, {headers: {'Content-Type': 'application/json'}}).then(response => {
        let entity =  response.data.result["entity"];
        this.formData.id = entity.id;
        let abencyBranchCount = response.data.result["agencyBranchCount"];
        if (abencyBranchCount == 0) {
          this.agencyId = entity.id;
          this.agencyName = entity.name;
          this.showBranch = true;
        }
        this.goodPaging();
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    }, update: function (data) {
      axios.get(`/api/agency/${data.id}`).then(response => {
        this.formData = Object.assign({}, response.data.result);
        this.isCollapsed = false;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    }, toBranch: function (data) {
      console.log("toBranch", data);
      this.showBranch = true;
      this.agencyId = data.id;
      this.agencyName = data.name;
      // this.$router.push({path: `/pages/regist/agencyBranchManage/${data.id}`});
    },

    goodPaging: function (isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;
      axios.post('/api/agency/agencySearchPaging', this.pagingParams, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    }, getOptions: function () {
      axios.post('/api/agency/getOptions', {}, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.prefectures = response.data.result.prefectures;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
  },watch:{
    isCollapsed:function(newValue){
      if(newValue){
        this.formInit();
      }
    }
  }
}
</script>
