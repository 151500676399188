export default {
    data () {
    },
    mounted() {
    },
    methods:{
        getRoleName(role){
            switch (role) {
                case 'ADMINISTRATOR':
                    return 'アドミニストレータ';
                case 'REGISTRATOR':
                    return 'レジストレータ';
                case 'MAINTENANCE':
                    return 'メンテナンス';
                case 'PROVIDER':
                    return 'プロバイダ';
                case 'DOCTOR':
                    return '医療関係者';
            }
        },isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
            } else {
                return true;
            }
        }
    },
    computed: {

    },watch:{

    }
}