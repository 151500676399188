var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "parts" }, [
    _c(
      "span",
      { staticClass: "float-left" },
      [
        _c(
          "CButton",
          {
            attrs: { color: "primary", size: "sm" },
            on: { click: _vm.formInit }
          },
          [_vm._v("入力クリア")]
        ),
        _vm._t("right")
      ],
      2
    ),
    _vm.showSaveBtn
      ? _c(
          "span",
          { staticClass: "float-right ml-5" },
          [
            _vm.id
              ? _c(
                  "CButton",
                  {
                    attrs: { size: "sm", color: "info" },
                    on: {
                      click: function($event) {
                        return _vm.save("更新確認", "更新しますか？")
                      }
                    }
                  },
                  [_vm._v("更新")]
                )
              : _c(
                  "CButton",
                  {
                    attrs: { size: "sm", color: "danger" },
                    on: {
                      click: function($event) {
                        return _vm.save("登録確認", "登録しますか？")
                      }
                    }
                  },
                  [_vm._v("登録")]
                ),
            _vm._t("left")
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }